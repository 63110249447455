.img1 {
    height: 750px;
}

.img2 {
    height: 600px;
}

.img3 {
    height: 600px;
}

.img5 {
    height: 600px;
}

.img10 {
    height: 700px;
}

/* h1 {
    font-size: clamp(1em, 4vw, 5em);
} */

.row {
    display: flex;
    flex-wrap: wrap;
    padding: 0 4px;
}

/* Create four equal columns that sits next to each other */
.column {
    flex: 50%;
    max-width: 50%;
    padding: 0 2em;
}

.column img {
    margin-top: 1em;
    vertical-align: middle;
    width: 100%;
}

.project-container h1 {
    font-family: 'Apple Chancery', 'cursive';
    font-size: 5em;
    font-size: clamp(2em, 4vw, 5em);
}

.global-container p {
    justify-content: left;
}

@media screen and (min-width: 769px) and (max-width: 1024px) {
    .column {
        flex: 50%;
        max-width: 50%;
    }

    .img1 {
        height: 450px;
    }

    .img2 {
        height: 350px;
    }

    .img3 {
        height: 350px;
    }

    .img5 {
        height: 350px;
    }

    .img10 {
        height: 450px;
    }
}


@media screen and (min-width: 651px) and (max-width: 768px) {
    .column {
        flex: 50%;
        max-width: 50%;
    }

    .img1 {
        height: 450px;
    }

    .img2 {
        height: 350px;
    }

    .img3 {
        height: 350px;
    }

    .img5 {
        height: 350px;
    }

    .img10 {
        height: 450px;
    }
}

/* Responsive layout - makes a two column-layout instead of four columns */
@media screen and (min-width: 551px) and (max-width: 650px) {
    .column {
        flex: 100%;
        max-width: 100%;
    }

    .column img {
        padding: 0 2em;
    }

    .img1 {
        height: 550px;
    }

    .img2 {
        height: 450px;
    }

    .img3 {
        height: 450px;
    }

    .img5 {
        height: 450px;
    }

    .img10 {
        height: 550px;
    }
}

/* Responsive layout - makes the two columns stack on top of each other instead of next to each other */
@media screen and (min-width: 425px) and (max-width: 550px) {
    .column {
        flex: 100%;
        max-width: 100%;
        padding: 0 3em;
    }

    .img1 {
        height: 575px;
    }

    .img2 {
        height: 425px;
    }

    .img3 {
        height: 400px;
    }

    .img5 {
        height: 450px;
    }

    .img10 {
        height: 475px;
    }
}

@media screen and (max-width: 320px) {
    .column {
        flex: 100%;
        max-width: 100%;
        padding: 0 3em;
    }

    .img1 {
        height: 300px;
    }

    .img2 {
        height: 250px;
    }

    .img3 {
        height: 250px;
    }

    .img10 {
        height: 300px;
    }

    .img5 {
        height: 300px;
    }
}

@media screen and (min-width: 321px) and (max-width: 375px) {
    .column {
        flex: 100%;
        max-width: 100%;
        padding: 0 3em;
    }

    .img1 {
        height: 350px;
    }

    .img2 {
        height: 300px;
    }

    .img3 {
        height: 250px;
    }

    .img10 {
        height: 350px;
    }

    .img5 {
        height: 300px;
    }

    button {
        font-family: 'Century Gothic', 'AppleGothic', sans-serif;
        cursor: pointer;
        color: white;
        background-color: black;
        font-size: 12px;
        border-radius: 30px;
        border: 2px solid white;
        padding: 0.5em 1em;
        font-weight: 700;
    }
}

@media screen and (min-width: 376px) and (max-width: 424px) {
    .column {
        flex: 100%;
        max-width: 100%;
        padding: 0 3em;
    }

    .icon {
        font-size: 50px;
    }

    .footer-icon {
        font-size: 4em;
    }


    .button {
        padding: 4em;
    }

    button {
        font-family: 'Century Gothic', 'AppleGothic', sans-serif;
        cursor: pointer;
        color: white;
        background-color: black;
        font-size: 12px;
        border-radius: 30px;
        border: 2px solid white;
        padding: 0.5em 1em;
        font-weight: 700;
    }

    .img1 {
        height: 350px;
    }

    .img2 {
        height: 300px;
    }

    .img3 {
        height: 300px;
    }

    .img10 {
        height: 350px;
    }

    .img5 {
        height: 350px;
    }
}

@media screen and (min-width: 1525px) {
    .column {
        flex: 50%;
        max-width: 50%;
    }

    .column img {
        padding: 0 2em;
    }

    .img1 {
        min-height: 1000px;
    }

    .img2 {
        min-height: 1000px;
    }

    .img3 {
        min-height: 1000px;
    }

    .img5 {
        min-height: 1000px;
    }

    .img10 {
        min-height: 1000px;
    }
}

@media screen and (min-width: 1025px) and (max-width: 1524px) {
    .column {
        flex: 50%;
        max-width: 50%;
    }

    .column img {
        padding: 0 2em;
    }

    .img1 {
        min-height: 750px;
    }

    .img2 {
        min-height: 750px;
    }

    .img3 {
        min-height: 750px;
    }

    .img5 {
        min-height: 750px;
    }

    .img10 {
        min-height: 750px;
    }
}