*,
*::before,
*::after {
    box-sizing: border-box;
}

.project {
    width: 800px;
}

.project-container {
    background-color: rgb(244, 205, 126);
}

.pie-project {
    width: 800px;
    height: 500px;
}

.html-project {
    width: 350px;
}

/* .about p {
    text-align: center;
} */

h1 {
    font-size: 2.5em;
    padding-top: 0.3em;
}

/* h1 {
    font-size: clamp(0.7em, .5rem + 5vw, 1em);
} */




@media screen and (max-width: 768px) {
    .project {
        max-width: 450px;
        height: 300px;
    }

    .pie-project {
        max-width: 450px;
        height: 300px;
    }

    .html-project {
        max-width: 400px;
        height: 650px;
    }

    h1 {
        font-size: 2.5em;
    }

    /* .global-container {
        text-align: center;
        font-size: clamp(1.6em, 2vw, 3em);
    } */
}


@media screen and (max-width: 425px) {
    .project {
        max-width: 300px;
        height: 200px;
    }

    .pie-project {
        max-width: 300px;
        height: 200px;
    }

    .html-project {
        max-width: 300px;
        height: 500px;
    }

    .global-container {
        text-align: center;
    }

    h1 {
        font-size: 2em;
    }
}